import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import './App.scss';
import MainPanel from './MainPanel/MainPanel';
import CardField from './UNO/CardField';
import NotFound from './404/NotFound';

const App: React.FC = () => {
  return (
    <Router>
      <div className="main"      >

        {/* HEADER */}
        <header className="top-panel">

          <NavLink
            to='/'
            className="logo"
          >
            <div className="logo__text">SI</div>
          </NavLink>

          <nav className="navigation">
            <NavLink
              to='/uno'
              activeClassName='navigation__route_active'
              className="navigation__route"
            >
              <div className="nav__text">???</div>
            </NavLink>

            <NavLink
              to='/about'
              activeClassName='navigation__route_active'
              className="navigation__route"
            >
              <div className="nav__text">About</div>
            </NavLink>

            <NavLink
              to='/contacts'
              activeClassName='navigation__route_active'
              className="navigation__route"
            >
              <div className="nav__text">Contacts</div>
            </NavLink>
          </nav>

        </header>

        <Switch>

          {/* INTRO SECTION */}
          <Route
            exact
            path='/'
            component={MainPanel}
          />

          {/* INTRO SECTION */}
          <Route
            path='/uno'
            component={CardField}
          />

          <Route
            component={NotFound}
          />

        </Switch>
      </div>
    </Router>
  );
}

export default App;