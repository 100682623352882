import React from 'react';
import lul from '../../assets/lul.png';
import { NavLink } from "react-router-dom";
import './NotFound.scss';

interface Props {

}

const NotFound = (props: Props) => {
  return (
    <div className='not-found-title'>

      <span className='not-found-title__number'>{'4'}</span>

      <NavLink to='/'>
        <img
          className='not-found-title__img'
          src={lul}
          alt='omegalul'
        />
      </NavLink>

      <span className='not-found-title__number'>{'4'}</span>

    </div>
  )
}

export default NotFound;
