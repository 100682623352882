import React from 'react';
import Card from './Card';
import './CardField.scss';

interface Props {

}

const CardField: React.FC = (props: Props) => {
  const cards: Array<{ character: string, color: string }> = [
    '#ff5555',
    '#ffaa00',
    '#55aa55',
    '#5555fd',
  ].map(color =>
    [
      ...range(0, 9),
      '+2',
      '\u20e0',
      '⮬⮯',
    ].map(character =>
      ({ character: `${character}`, color })
    )
  ).flat();

  return (
    <div className='card-field'>
      <div className='card-field__content-wrapper'>
        <div className='card-field__content'>
          {
            cards.map(({ character, color }) =>
              <Card
                key={character + color}
                character={character}
                color={color}
              />
            )
          }
        </div>
      </div>
    </div>
  );
}

function* range(start: number, end: number): any {
  yield start;
  if (start === end) return;
  yield* range(start + 1, end);
}

export default CardField;
