import React from 'react';
import cardBack from '../../assets/UNO-main-logo.svg';
import './Card.scss';

interface Props {
  character: string,
  color: string
}

const Card: React.FC<Props> = ({ character, color }: Props) => {
  let style: Object = {
    color,
    marginLeft: '-5px',
  };

  switch (character) {
    case '⮬⮯':
      style = {
        ...style,
        letterSpacing: '-30px',
        marginLeft: '-25px',
      };
      break;

    case '\u20e0':
      style = {
        ...style,
        fontStyle: 'normal',
        marginTop: '-5px',
        marginLeft: '-5px',
      };
      break;

    default:
      break;
  }

  return (
    <div className="card">
      <div className="front">
        <div
          className="front-content"
          style={{
            background: color,
          }}
        >
          <div className="front-ellipse" />
          <div
            className="top-sign"
            style={{
              letterSpacing: character === '⮬⮯' ? '-5px' : '0',
              fontStyle: character === '\u20e0' ? 'normal' : 'italic',
            }}
          >
            {character}
          </div>
          <div
            className="center-sign"
            style={style}
          >
            {character}
          </div>
          <div
            className="bottom-sign"
            style={{
              letterSpacing: character === '⮬⮯' ? '-5px' : '0',
              fontStyle: character === '\u20e0' ? 'normal' : 'italic',
            }}
          >
            {character}
          </div>
        </div>
      </div>
      <div className="back">
        <div className="back-content">
          <img className="back-content-logo" src={cardBack} alt="UNO" />
        </div>
      </div>
    </div>
  );
}

export default Card;
