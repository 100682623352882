import React, { useState, useRef } from 'react';
import './MainPanel.scss';

interface Props {
}

const MainPanel: React.FC = (props: Props) => {
  const [mouseCoords, setMouseCoords] = useState({
    top: `50%`,
    left: `50%`,
  });
  const mainNode = useRef<HTMLDivElement>(null);
  const eye = useRef<HTMLDivElement>(null);

  const handleMouseMove = (e: React.MouseEvent) => {
    const eyeCoords = eye.current && eye.current!.getBoundingClientRect();
    const top = eyeCoords && Math.round(e.clientY / (eyeCoords.top * 2) * 100);
    const left = eyeCoords && Math.round(e.clientX / ((eyeCoords.left * 2) + eyeCoords.width) * 100);

    setTimeout(() => {
      top && left && setMouseCoords({
        top: `${top <= 33 ? 33 : top >= 65 ? 65 : top}%`, // 30 70
        left: `${left <= 30 ? 30 : left >= 70 ? 70 : left}%`, // 25 75
      })
    }, 50);
  };

  return (
    <section
      ref={mainNode}
      className="intro-panel"
      onMouseMove={handleMouseMove}
    >

      <div className="headings">

        <h1
          className="headings__primary-title"
        >
          {
            `Simeon Ivanov`.split('').map((char, i, arr) =>
              <span
                ref={char === 'o' ? eye : null}
                key={char + i}
                className='name-character'
              >
                {
                  char === ' ' ? <br /> : char
                }
                {
                  char === 'o' &&
                  <>
                    {/* eye ball */}
                    <span
                      className='name-character__eye'
                      style={{
                        top: mouseCoords.top,
                        left: mouseCoords.left,
                      }}
                    >
                      {'•'}
                    </span>

                    {/* lashes */}
                    <span
                      className='top-lash'
                    />
                    <span
                      className='bottom-lash'
                    />
                  </>
                }
                {
                  // tongue
                  i === arr.length - 1 &&
                  <span className='tongue' />
                }
              </span>
            )
          }
        </h1>

        <h3
          className="headings__secondary-title"
        >
          {`Frontend developer`}
        </h3>
      </div>


    </section>
  );
}

export default MainPanel;
